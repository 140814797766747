import React from 'react'
import HappyIcon from '../../images/happy-wires-icon.inline.svg';
import WireTypesIcon from '../../images/wire-types-icon.inline.svg';
// import HandIcon from '../../images/hand-icon.inline.svg';
import SizesIcon from '../../images/twoSizes.inline.svg';
import './sectionIcons.styles.scss';

const SectionIcons = () => { 
    return (
        <section className={`section-container`}>
                  <div className="section-icons">
                   
                    <div className="section-icon-wrap">
                      <WireTypesIcon className="section-icon"/>
                      <h3>Versatile</h3>
                      <p>Fit multiple wire types of various lengths and dimensions</p>
                    </div>

                    <div className="section-icon-wrap">
                      <HappyIcon className="section-icon"/>
                      <h3>Happy Cables</h3>
                      <p>Gone are the days of cluttered desks and messy cables.</p>
                    </div>

                    <div className="section-icon-wrap sizes-icon">
                      <SizesIcon className="section-icon" style={{width: '50%'}}/>
                      <h3>2 Sizes</h3>
                      <p>Practical sizes for your everyday electronics whether stationary or on the go.</p>
                    </div>
                    
                  </div>
            </section>
    )
}

export default SectionIcons;